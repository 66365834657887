import { User } from "@auth0/auth0-react";
import { useCurrentUser } from "src/api/useCurrentUser";

export enum USER_ROLE {
  Admin = "Admin",
  AccountManager = "Account Manager",
  UserManager = "User Manager",
  User = "User",
}

export const ROLES = [
  USER_ROLE.Admin,
  USER_ROLE.AccountManager,
  USER_ROLE.UserManager,
  USER_ROLE.User,
];

// FIXME: Why if yhe order is the same like in ROLES and USER_ROLE enum ?
export const rolesOrder = [
  USER_ROLE.Admin,
  USER_ROLE.AccountManager,
  USER_ROLE.UserManager,
  USER_ROLE.User,
];

export const getLowerRoles = (role: USER_ROLE | ""): string[] => {
  if (role) {
    const index = rolesOrder.indexOf(role);
    return index !== -1 ? rolesOrder.slice(index) : [];
  }
  return [];
};

export const ACTIVITY_LOG_ROLES = [USER_ROLE.Admin, USER_ROLE.AccountManager];

export const STATUS_LIST = ["active", "trial", "expired", "disabled"];

export const getRoleFromUser = (user?: User) => {
  const noRole = "n/a";
  const roleKey = user && Object.keys(user).find((key) => key.includes("role"));

  if (!user || !roleKey) {
    return noRole;
  }

  const roleObj = user[roleKey];

  return Array.isArray(roleObj) ? roleObj.join(", ") : `${roleObj}`;
};

export const getSelectedRoles = (role: string) => {
  switch (role) {
    case USER_ROLE.Admin:
      return ROLES;
    case USER_ROLE.AccountManager:
      return ROLES.filter((r) => r !== USER_ROLE.Admin);
    case USER_ROLE.UserManager:
      return [role, USER_ROLE.User];
    default:
      return [USER_ROLE.User];
  }
};

export function getAuthUserRole(value: string): USER_ROLE | "" {
  if (value.includes(USER_ROLE.Admin)) return USER_ROLE.Admin;
  if (value.includes(USER_ROLE.AccountManager)) return USER_ROLE.AccountManager;
  if (value.includes(USER_ROLE.UserManager)) return USER_ROLE.UserManager;
  if (value.includes(USER_ROLE.User)) return USER_ROLE.User;
  return "";
}

/** This returns the highest role of the authenticated user
 * or impersonating user if impersonating mode is enabled */
export const useCurrentUserHighestRole = () => {
  const { data: currentUser } = useCurrentUser();
  const currentUserRole = currentUser?.roles?.join(", ") || "";
  return getAuthUserRole(currentUserRole);
};
