import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicUserInviteAcceptedPathParams = {
  inviteId: string;
};

export const publicUserInviteAcceptedRoute =
  new AppRouteLazyBuilder<PublicUserInviteAcceptedPathParams>({
    path: "/public/invite/:inviteId/accepted",
    lazy: async () => {
      const { PublicUserInviteAcceptedPage } = await import(
        "./PublicUserInviteAccepted.page"
      );
      return { Component: PublicUserInviteAcceptedPage };
    },
  });
