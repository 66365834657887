import {
  Box,
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useConsentManagementUpdate } from "src/api/useConsentManagementUpdate";
import { useSessionContext } from "src/api/useSessionContext";
import { useOpenState } from "src/utils/useOpenState";
import { DialogPromptProps } from "../DialogPrompt/DialogPrompt.model";
import { CustomizeConsentPreferencesDialog } from "../CustomizeConsentPreferencesDialog/CustomizeConsentPreferencesDialog";

type ConsentDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm" | "open" | "onClose"
> & {
  closeOnLeftSide?: boolean;
};

const defaultState = {
  permitNecessary: true,
  permitFunctional: true,
  permitAnalytics: true,
};

export const ConsentDialog = ({
  closeOnLeftSide,
  ...props
}: ConsentDialogProps) => {
  const { palette } = useTheme();

  const { userId, userConsent, isImpersonatingUser } = useSessionContext();

  const consentDialogState = useOpenState(true);
  const isDialogOpened =
    !userConsent && !isImpersonatingUser && consentDialogState.isOpen;

  const { hide, isOpen, show } = useOpenState();

  const handleCustomise = () => {
    show();
    consentDialogState.hide();
  };

  const updateConsentPreferences = useConsentManagementUpdate({
    options: {
      onSettled() {
        consentDialogState.hide();
      },
    },
  });

  const acceptDefault = () => {
    updateConsentPreferences.mutate({
      path: {
        id: userId,
        permitNecessary: defaultState.permitNecessary,
        permitFunctional: defaultState.permitFunctional,
        permitAnalytics: defaultState.permitAnalytics,
      },
    });
  };

  return (
    <>
      <Dialog
        {...props}
        open={isDialogOpened}
        onClose={consentDialogState.hide}
        maxWidth="xs"
        PaperProps={{
          elevation: 23,
        }}
        sx={{
          [`.${dialogClasses.container}`]: {
            alignItems: "end",
          },
        }}
        hideBackdrop
      >
        <Stack>
          <DialogTitle sx={{ px: 3, py: 0.5 }} columnGap={2}>
            {closeOnLeftSide && (
              <IconButton
                edge="end"
                id="close-consent-management"
                onClick={consentDialogState.hide}
              >
                <Close />
              </IconButton>
            )}

            <Box>We Value Your Consent</Box>

            {!closeOnLeftSide && (
              <IconButton
                edge="end"
                id="close-consent-management"
                onClick={consentDialogState.hide}
              >
                <Close />
              </IconButton>
            )}
          </DialogTitle>
          <Divider />
        </Stack>

        <DialogContent sx={{ py: 2 }}>
          <Box
            m="auto"
            display="flex"
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="body1">
              We use cookies to improve your browsing experience, deliver
              personalized ads and content, and analyze our traffic. By clicking
              “Accept Default”, you agree to our use of cookies.
            </Typography>
            <Stack flexDirection="row" justifyContent="flex-end" gap={2} pt={2}>
              <Button
                sx={{ color: palette.text.primary }}
                variant="outlined"
                onClick={handleCustomise}
              >
                Customise
              </Button>
              <Button variant="contained" onClick={acceptDefault}>
                Accept Default
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      <CustomizeConsentPreferencesDialog open={isOpen} onClose={hide} />
    </>
  );
};
