import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicTrialClipViewerPathParams = {
  reportId: string;
  eventId: string;
};

export const publicTrialClipViewerRoute =
  new AppRouteLazyBuilder<PublicTrialClipViewerPathParams>({
    path: "/public/trial/:reportId/:eventId",
    lazy: async () => {
      const { PublicTrialClipViewerPage } = await import(
        "./PublicTrialClipViewer.page"
      );
      return { Component: PublicTrialClipViewerPage };
    },
  });
