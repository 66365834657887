import { Suspense } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { PushNotificationProvider } from "./providers/PushNotificationProvider/PushNotificationProvider";
import { SessionProvider } from "./api/useSessionContext";
import { Auth0ProviderWithConfig } from "./providers/Auth0ProviderWithConfig";
import { AppLayout } from "./components/AppLayout";
import { AppLayoutPublic } from "./components/AppLayoutPublic";
import { AppLayoutHubSpot } from "./components/AppLayoutHubSpot";
import { AppRouterRoot } from "./components/AppRouterRoot/AppRouterRoot";
import { PageLoader } from "./components/PageLoader";
import { CheckAccountRegistered } from "./components/CheckAccountRegistered";
import { PageNotFoundPage } from "./pages/PageNotFound/PageNotFound.page";
import { devPlaygroundRoute } from "./pages/DevPlayground/DevPlayground.route";
import { watchListRoute } from "./pages/WatchList/WatchList.route";
import { watchListCreateRoute } from "./pages/WatchListCreate/WatchListCreate.route";
import { watchListUpdateRoute } from "./pages/WatchListUpdate/WatchListUpdate.route";
import { watchListTermResultClipRoute } from "./pages/WatchListTermResultClip/WatchListTermResultClip.route";
import { watchListTermResultsRoute } from "./pages/WatchListTermResults/WatchListTermResults.route";
import { watchListTermResultClipEditorRoute } from "./pages/WatchListTermResultClipEditor/WatchListTermResultClipEditor.route";
import { analyticsLiteRoute } from "./pages/ReportAnalysisLite/AnalyticsLite.route";
import { featureGateDemoRoute } from "./pages/FeatureGateDemo/FeatureGateDemo.route";
import { powerSearchRoute } from "./pages/PowerSearch/PowerSearch.route";
import { powerSearchResultRoute } from "./pages/PowerSearchResult/PowerSearchResult.route";
import { powerSearchResultAdvancedRoute } from "./pages/PowerSearchResultAdvanced/PowerSearchResultAdvanced.route";
import { powerSearchResultClipRoute } from "./pages/PowerSearchResultClip/PowerSearchResultClip.route";
import { powerSearchResultClipEditorRoute } from "./pages/PowerSearchResultClipEditor/PowerSearchResultClipEditor.route";
import { reportsRoute } from "./pages/Reports/Reports.route";
import { reportCreateRoute } from "./pages/ReportCreate/ReportCreate.route";
import { reportEditRoute } from "./pages/ReportEdit/ReportEdit.route";
import { reportAnalysisRoute } from "./pages/ReportAnalysis/ReportAnalysis.route";
import { pageNotFoundRoute } from "./pages/PageNotFound/PageNotFound.route";
import { auth0CallbackRoute } from "./pages/Auth0Callback/Auth0Callback.route";

import { publicReportRoute } from "./pages/PublicReport/PublicReport.route";
import { publicReportPDFRoute } from "./pages/PublicReportPDF/PublicReportPDF.route";
import { publicShareEventsRoute } from "./pages/PublicShareEvents/PublicShareEvents.route";
import { publicReportEventRoute } from "./pages/PublicReportEvent/PublicReportEvent.route";
import { publicSharedEventRoute } from "./pages/PublicSharedEvent/PublicSharedEvent.route";
import { publicEventRoute } from "./pages/PublicEvent/PublicEvent.route";
import { publicYoutubePodcastEventRoute } from "./pages/PublicYoutubePodcastEvent/PublicYoutubePodcastEvent.route";
import { publicMediaCenterItemRoute } from "./pages/PublicMediaCenterItem/PublicMediaCenterItem.route";
import { publicFileDownloadRoute } from "./pages/PublicFileDownload/PublicFileDownload.route";
import { publicTermsOfServiceRoute } from "./pages/PublicTermsOfService/PublicTermsOfService.route";
import { publicUserInviteRoute } from "./pages/PublicUserInvite/PublicUserInvite.route";
import { publicUserInviteAcceptedRoute } from "./pages/PublicUserInviteAccepted/PublicUserInviteAccepted.route";
import { unsubscribeAlertsRoute } from "./pages/UnsubscribeAlerts/UnsubscribeAlerts.route";
import { snapshotsRoute } from "./pages/Snapshots/Snapshots.route";
import { snapshotsClipViewerRoute } from "./pages/SnapshotsClipViewer/SnapshotsClipViewer.route";
import { mediaCenterRoute } from "./pages/MediaCenter/MediaCenter.route";
import { settingsRoute } from "./pages/Settings/Settings.route";
import { snapshotsClipEditorRoute } from "./pages/SnapshotsClipEditor/SnapshotsClipEditor.route";
import { mediaCenterClipRoute } from "./pages/MediaCenterClip/MediaCenterClip.route";
import { userManagementRoute } from "./pages/UserManagement/UserManagement.route";
import { accountsManagementCreateAccountRoute } from "./pages/AccountsManagementCreateAccount/AccountsManagementCreateAccount.route";
import { accountsManagementRoute } from "./pages/AccountsManagement/AccountsManagement.route";
import { userManagementCreateRoute } from "./pages/UserManagementCreate/UserManagementCreate.route";
import { accountsManagementEditAccountRoute } from "./pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { accountsManagementAddUserRoute } from "./pages/AccountsManagementAddUser/AccountsManagementAddUser.route";
import { userManagementUpdateRoute } from "./pages/UserManagementUpdate/UserManagementUpdate.route";
import { dateTimeSearchInitialRoute } from "./pages/DateTimeSearchInitial/DateTimeSearchInitial.route";
import { dateTimeSearchResultsRoute } from "./pages/DateTimeSearchResults/DateTimeSearchResults.route";
import { DateTimeSearchRoot } from "./pages/DateTimeSearchRoot/DateTimeSearchRoot.page";
import { dateTimeSearchClipRoute } from "./pages/DateTimeSearchClip/DateTimeSearchClip.route";
import { dateTimeSearchClipEditorRoute } from "./pages/DateTimeSearchClipEditor/DateTimeSearchClipEditor.route";
import { unsubscribeRoute } from "./pages/Unsubscribe/Unsubscribe.route";
import { downloadFileRoute } from "./pages/DownloadFile/DownloadFile.route";
import { accountsManagementActivityLogsRoute } from "./pages/AccountsManagementActivityLogs/AccountsManagementActivityLogs.route";
import { userManagementActivityLogsRoute } from "./pages/UserManagementActivityLogs/UserManagementActivityLogs.route";
import { accountsManagementUserActivityLogsRoute } from "./pages/AccountsManagementUserActivityLogs/AccountsManagementUserActivityLogs.route";
import { publicViewByQueryRoute } from "./pages/PublicViewByQuery/PublicViewByQuery.route";
import { publicViewBySearchRoute } from "./pages/PublicViewBySearch/PublicViewBySearch.route";
import { publicTrialClipViewerRoute } from "./pages/PublicTrialClipViewer/PublicTrialClipViewer.route";
import { publicReportClipViewerRoute } from "./pages/PublicReportClipViewer/PublicReportClipViewer.route";
import { publicFftResultRoute } from "./pages/PublicFftResult/PublicFftResult.route";
import { IntercomProvider } from "./providers/IntercomProvider";
import { appErrorPageRoute } from "./pages/AppErrorPage/AppErrorPage.route";
import { AppErrorPage } from "./pages/AppErrorPage/AppErrorPage.page";
import { hubSpotFFTCampaignCreateRoute } from "./pages/HubSpotFFTCampaignCreate/HubSpotFFTCampaignCreate.route";
import { hubSpotFFTCampaignUpdateRoute } from "./pages/HubSpotFFTCampaignUpdate/HubSpotFFTBuilderUpdate.route";
import { hubSpotFFTCampaignsRoute } from "./pages/HubSpotFFTCampaigns/HubSpotFFTCampaigns.route";
import { hubSpotFFTAssignContactsRoute } from "./pages/HubSpotFFTAssignContacts/HubSpotFFTAssignContacts.route";
import { hubSpotFFTAssignCampaignsRoute } from "./pages/HubSpotFFTAssignCampaigns/HubSpotFFTAssignCampaigns.route";
import { hubSpotFFTCampaignActivateRoute } from "./pages/HubSpotFFTCampaignActivate/HubSpotFFTCampaignActivate.route";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <AppErrorPage />,
    element: (
      <Auth0ProviderWithConfig>
        <SessionProvider>
          <PushNotificationProvider>
            <IntercomProvider>
              <AppRouterRoot element={<Outlet />} />
            </IntercomProvider>
          </PushNotificationProvider>
        </SessionProvider>
      </Auth0ProviderWithConfig>
    ),
    children: [
      auth0CallbackRoute,
      {
        path: "/hub-spot",
        element: <CheckAccountRegistered component={AppLayoutHubSpot} />,
        children: [
          {
            index: true,
            element: (
              <Navigate to={hubSpotFFTCampaignCreateRoute.path} replace />
            ),
          },
          hubSpotFFTCampaignsRoute,
          hubSpotFFTCampaignCreateRoute,
          hubSpotFFTCampaignUpdateRoute,
          hubSpotFFTCampaignActivateRoute,
          hubSpotFFTAssignContactsRoute,
          hubSpotFFTAssignCampaignsRoute,
        ],
      },
      {
        path: "/public",
        element: <AppLayoutPublic />,
        children: [
          publicReportRoute,
          publicReportPDFRoute,
          publicShareEventsRoute,
          publicReportEventRoute,
          publicReportClipViewerRoute,
          publicSharedEventRoute,
          publicMediaCenterItemRoute,
          publicEventRoute,
          publicYoutubePodcastEventRoute,
          publicFileDownloadRoute,
          publicTermsOfServiceRoute,
          publicUserInviteRoute,
          publicUserInviteAcceptedRoute,
          publicViewBySearchRoute,
          publicViewByQueryRoute,
          publicFftResultRoute,
          publicTrialClipViewerRoute,
        ],
      },

      {
        path: "/",
        errorElement: <AppErrorPage />,
        element: <CheckAccountRegistered component={AppLayout} />,
        children: [
          downloadFileRoute,
          {
            index: true,
            element: <Navigate to={watchListRoute.path} replace />,
          },
          watchListRoute,
          watchListCreateRoute,
          watchListUpdateRoute,
          watchListTermResultsRoute,
          watchListTermResultClipRoute,
          watchListTermResultClipEditorRoute,
          //
          powerSearchRoute,
          powerSearchResultRoute,
          powerSearchResultAdvancedRoute,
          powerSearchResultClipRoute,
          powerSearchResultClipEditorRoute,
          //
          reportsRoute,
          reportCreateRoute,
          reportEditRoute,
          reportAnalysisRoute,
          analyticsLiteRoute,
          //
          {
            path: "/date-time-search",
            element: <DateTimeSearchRoot />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={dateTimeSearchInitialRoute.path} replace />
                ),
              },
              dateTimeSearchInitialRoute,
              dateTimeSearchResultsRoute,
              dateTimeSearchClipRoute,
              dateTimeSearchClipEditorRoute,
            ],
          },

          //
          snapshotsRoute,
          snapshotsClipViewerRoute,
          snapshotsClipEditorRoute,
          //
          mediaCenterRoute,
          mediaCenterClipRoute,
          //
          userManagementRoute,
          userManagementCreateRoute,
          userManagementUpdateRoute,
          userManagementActivityLogsRoute,
          //
          accountsManagementUserActivityLogsRoute,
          accountsManagementActivityLogsRoute,
          accountsManagementAddUserRoute,
          accountsManagementCreateAccountRoute,
          accountsManagementEditAccountRoute,
          accountsManagementRoute,
          //
          featureGateDemoRoute,
          appErrorPageRoute,
          //
          devPlaygroundRoute,
          //
          settingsRoute,
        ],
      },
      {
        path: "/unsubscribe",
        errorElement: <PageNotFoundPage isUnregistered />,
        children: [
          unsubscribeRoute,
          {
            path: "/unsubscribe/alerts",
            element: <AppLayoutPublic />,
            children: [unsubscribeAlertsRoute],
          },
        ],
      },
      pageNotFoundRoute,
    ],
  },
]);

const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY || "";
LicenseInfo.setLicenseKey(muiLicenseKey);

export const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <RouterProvider router={router} fallbackElement={<PageLoader />} />
    </Suspense>
  );
};
