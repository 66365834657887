import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type HubSpotFFTCampaignUpdateRouteQueryParams = {
  campaign_id: string;
};

export const hubSpotFFTCampaignUpdateRoute = new AppRouteLazyBuilder<
  void,
  HubSpotFFTCampaignUpdateRouteQueryParams
>({
  path: "/hub-spot/fft/update",
  lazy: async () => {
    const { HubSpotFFTCampaignUpdatePage: FFTBuilderUpdate } = await import(
      "./HubSpotFFTBuilderUpdate.page"
    );
    return { Component: FFTBuilderUpdate };
  },
});
