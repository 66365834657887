import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type HubSpotFFTCampaignsRouteQueryParams = {
  company_id: string;
};

export const hubSpotFFTCampaignsRoute = new AppRouteLazyBuilder<
  void,
  HubSpotFFTCampaignsRouteQueryParams
>({
  path: "/hub-spot/fft/campaigns/:companyId",
  lazy: async () => {
    const { HubSpotFFTCampaignsPage: FFTCampaignsPage } = await import(
      "./HubSpotFFTCampaigns.page"
    );
    return { Component: FFTCampaignsPage };
  },
});
