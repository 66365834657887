import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicFftResultPathParams = {
  reportId: string;
};

export const publicFftResultRoute =
  new AppRouteLazyBuilder<PublicFftResultPathParams>({
    path: "/public/trial/:reportId",
    lazy: async () => {
      const { PublicFftResult } = await import("./PublicFftResult.page");
      return { Component: PublicFftResult };
    },
  });
