import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AppQueryParamsProvider } from "src/providers/AppQueryParamsProvider";

export const AppLayoutHubSpot = () => {
  return (
    <Stack id="app-layout-hub-spot-root" width="100%" height="100%">
      <AppQueryParamsProvider>
        <Outlet />
      </AppQueryParamsProvider>
    </Stack>
  );
};
