import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useIsImpersonatingUser } from "src/api/useIsImpersonatingUser";
import { useIsDemoMode } from "src/api/useIsDemoMode";
import { useCurrentUser } from "src/api/useCurrentUser";
import { userManagementUpdateRoute } from "src/pages/UserManagementUpdate/UserManagementUpdate.route";
import { useImpersonateUser } from "src/api/useImpersonateUser";
import { userManagementRoute } from "src/pages/UserManagement/UserManagement.route";
import { DemoModeHandle } from "../DemoModeHandle";
import { StopImpersonateUserFAB } from "../StopImpersonateUserFAB/StopImpersonateUserFAB";

export const DemoAndImpersonateLayout = () => {
  const theme = useTheme();
  const { data: currentUser } = useCurrentUser();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isImpersonatingUser = useIsImpersonatingUser();
  const isDemoMode = useIsDemoMode();
  const impersonateUser = useImpersonateUser({
    options: {
      onSuccess: () => {
        if (currentUser?.id) {
          const impersonatedUserRoute = userManagementUpdateRoute.makeUrl({
            userId: currentUser.id,
          });
          window?.location.replace(impersonatedUserRoute);
        } else {
          window?.location.replace(userManagementRoute.path);
        }
      },
    },
  });
  const stopImpersonating = () => {
    impersonateUser.mutate({
      params: {
        path: {
          id: "00000000-0000-0000-0000-000000000000",
        },
      },
    });
  };

  const impersonationFrame = isImpersonatingUser && !isDemoMode && (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        border: `4px solid ${theme.palette.error.main}`,
        zIndex: 99999,
        pointerEvents: "none",
      }}
    />
  );

  const demoImpersonationHandle = isDemoMode && <DemoModeHandle />;
  return (
    <>
      {impersonationFrame}
      {demoImpersonationHandle}
      {isImpersonatingUser && !isDemoMode && !isMobile && (
        <StopImpersonateUserFAB onClick={stopImpersonating} />
      )}
    </>
  );
};
