import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  BoxProps,
  Button,
  IconButton,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useIsUserRegistered } from "src/api/useIsUserRegistered";
import { Logo } from "src/components/logo/Logo";
import { LogoEye } from "src/components/logo/LogoEye";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { AppQueryParamsProvider } from "src/providers/AppQueryParamsProvider";
import { useLogout } from "src/utils/useLogout";

// TODO remove this unused page
export const UnregisteredUserPage = () => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();

  // if registered users lands here - redirect him to WatchList
  const isUserRegistered = useIsUserRegistered();
  useEffect(() => {
    if (isUserRegistered) {
      navigate(watchListRoute.path, { replace: true });
    }
  }, [isUserRegistered, navigate]);

  const logout = useLogout();
  const handleLogout = () => {
    logout();
  };

  const logoSize: BoxProps["width"] = {
    xs: 40,
    sm: 64,
  };

  const avatar = (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        color: palette.background.brand,
        backgroundColor: "white",
      }}
      alt="Avatar icon"
    />
  );

  return (
    <AppQueryParamsProvider>
      <Stack mx="auto" maxWidth={884} width="100%" height="100%" flexGrow={1}>
        <Stack
          direction="row"
          alignItems="center"
          bgcolor={palette.background.brand}
          color={palette.getContrastText(palette.background.brand)}
          height={{ xs: 100, sm: 102 }}
          columnGap={{ xs: 2, md: 3 }}
          py={{ xs: 1, md: 2 }}
          px={{ xs: 2, md: 4 }}
        >
          <LogoEye dark pt={1} maxHeight={logoSize} maxWidth={logoSize} />
          <Stack flex={1}>
            <Typography variant="subtitle1">TVEyes Insight</Typography>

            <Typography
              variant="body2"
              color={alpha(
                palette.getContrastText(palette.background.brand),
                0.7
              )}
            >
              Advanced Media Monitoring
            </Typography>
          </Stack>

          {isMobile ? (
            <IconButton sx={{ pl: 0, pr: 0 }}>{avatar}</IconButton>
          ) : (
            <Button startIcon={avatar} color="inherit" onClick={handleLogout}>
              Log Out
            </Button>
          )}
        </Stack>

        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          gap={4}
        >
          <Logo height={40} />
          <Stack
            justifyContent="center"
            alignItems="center"
            gap={2}
            width={isMobile ? "!00%" : 586}
          >
            <Typography variant="h5" textAlign="center">
              This page is restricted to registered users only.
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="center"
            >
              Please sign in or create an account to access the content and
              features of this page. If you believe you should have access,
              please contact support for assistance.
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap={isMobile ? 2 : 3}>
            <Button
              href="https://www.tveyes.com/"
              variant="contained"
              sx={{ height: 42, width: 175 }}
            >
              Go to home page
            </Button>
            <Button
              href="mailto://support@tveyes.com"
              color="secondary"
              variant="contained"
              sx={{ height: 42, width: 175 }}
            >
              Support contact
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </AppQueryParamsProvider>
  );
};
