import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type HubSpotFFTActivateCampaignsRouteQueryParams = {
  company_id: string;
  campaign_id: string;
};

export const hubSpotFFTCampaignActivateRoute = new AppRouteLazyBuilder<
  void,
  HubSpotFFTActivateCampaignsRouteQueryParams
>({
  path: "/hub-spot/fft/activate-campaign",
  lazy: async () => {
    const { HubSpotFFTAssignContactsPage } = await import(
      "./HubSpotFFTCampaignActivate.page"
    );
    return { Component: HubSpotFFTAssignContactsPage };
  },
});
