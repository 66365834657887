import { useState } from "react";
import {
  AccountCircle,
  ChevronRight,
  Logout,
  Settings,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  useTheme,
} from "@mui/material";
import { settingsRoute } from "src/pages/Settings/Settings.route";
import { useProfileImage } from "src/api/useProfileImage";
import { useSessionContext } from "src/api/useSessionContext";
import { useSessionDelete } from "src/api/useSessionDelete";
import {
  useCurrentUserHighestRole,
  USER_ROLE,
} from "src/utils/useUserRoleList";
import { accountsManagementEditAccountRoute } from "src/pages/AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { publicTermsOfServiceRoute } from "src/pages/PublicTermsOfService/PublicTermsOfService.route";
import { useCurrentUser } from "src/api/useCurrentUser";
import { useLogout } from "src/utils/useLogout";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { ProfileButtonMenuItem } from "./ProfileButtonMenuIcon";
import { TermsOfUseIcon } from "../icons/TermsOfUsage";

export const ProfileButton = (props: { collapsed?: boolean }) => {
  const { data: currentUser } = useCurrentUser();
  const sessionDelete = useSessionDelete();
  const { accountId, isImpersonatingUser } = useSessionContext();

  const theme = useTheme();
  const { palette } = useTheme();
  const logout = useLogout();
  const userHighestRole = useCurrentUserHighestRole();

  const { data } = useProfileImage();

  const userName =
    isImpersonatingUser === true
      ? currentUser
        ? `${currentUser?.firstName} ${currentUser?.lastName}`
        : "Impersonated User"
      : `${currentUser?.fullName || ""}`;

  const accountUrl = accountsManagementEditAccountRoute.makeUrl({
    accountId,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    // wait for session deletion
    await sessionDelete.mutateAsync();
    logout();
  };

  return (
    <TooltipBase
      placement="right"
      title={`${userName}, ${userHighestRole}`}
      disableHoverListener={Boolean(anchorEl)}
    >
      <ListItem alignItems="center" disablePadding disableGutters>
        <ListItemButton
          id="profile-button"
          onClick={openProfileMenu}
          disabled={sessionDelete.isLoading}
          sx={{
            paddingX: 0.5,
            paddingY: 0,
            borderRadius: 2,
          }}
        >
          <ListItemAvatar>
            <Avatar
              sx={{ width: 32, height: 32, m: 0.5 }}
              alt={userName}
              src={data?.image}
              imgProps={{
                referrerPolicy: "no-referrer",
              }}
            />
          </ListItemAvatar>
          <ListItemText
            sx={{
              margin: 0,
              opacity: props.collapsed ? 0 : 1,
              transition: theme.transitions.create("opacity", {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
            primary={<Typography noWrap>{userName}</Typography>}
            secondary={
              <Typography color={palette.primary.main} noWrap>
                {userHighestRole}
              </Typography>
            }
          />
          <ListItemIcon
            sx={{
              minWidth: "unset",
              opacity: props.collapsed ? 0 : 1,
              transition: theme.transitions.create("opacity", {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          >
            <ChevronRight />
          </ListItemIcon>
        </ListItemButton>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            // offset menu content farther from anchorEl
            ml: 1.2,
            mt: 1.8,
          }}
        >
          <ProfileButtonMenuItem
            path={settingsRoute.makeUrl()}
            label="My Settings"
            tooltip="My Settings"
            icon={<Settings />}
            onClick={handleClose}
          />

          {(userHighestRole === USER_ROLE.Admin ||
            userHighestRole === USER_ROLE.AccountManager) && (
            <ProfileButtonMenuItem
              path={accountUrl}
              label="My Account"
              tooltip="My Account"
              icon={<AccountCircle />}
              onClick={handleClose}
            />
          )}
          <Divider />

          <ProfileButtonMenuItem
            path={publicTermsOfServiceRoute.makeUrl()}
            label="Terms of Use"
            tooltip="Terms of Use"
            icon={<TermsOfUseIcon />}
            onClick={handleClose}
            target="_blank"
          />

          <Divider />

          <ProfileButtonMenuItem
            label="Log Out"
            tooltip="Log Out"
            icon={<Logout />}
            onClick={handleLogout}
          />
        </Menu>
      </ListItem>
    </TooltipBase>
  );
};
