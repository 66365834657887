import { useAuth0 } from "@auth0/auth0-react";

export const useLogout = () => {
  const { logout } = useAuth0();

  return (returnUrl?: string) => {
    // clear user preferences
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: returnUrl || window.location.origin,
      },
    });
  };
};
