import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type HubSpotFFTCampaignCreateRouteQueryParams = {
  company_id: string;
  owner_id: string;
};

export const hubSpotFFTCampaignCreateRoute = new AppRouteLazyBuilder<
  void,
  HubSpotFFTCampaignCreateRouteQueryParams
>({
  path: "/hub-spot/fft/builder",
  lazy: async () => {
    const { HubSpotFFTCampaignCreate: FFTBuilder } = await import(
      "./HubSpotFFTCampaignCreate.page"
    );
    return { Component: FFTBuilder };
  },
});
