import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type HubSpotFFTAssignCampaignsQueryParams = {
  companyId: string;
  campaignId: string;
  ownerId: string;
};

export const hubSpotFFTAssignCampaignsRoute = new AppRouteLazyBuilder<
  void,
  HubSpotFFTAssignCampaignsQueryParams
>({
  path: "/hub-spot/fft/assign-campaigns",
  lazy: async () => {
    const { HubSpotFFTAssignCampaignsPage } = await import(
      "./HubSpotFFTAssignCampaigns.page"
    );
    return { Component: HubSpotFFTAssignCampaignsPage };
  },
});
