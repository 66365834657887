import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import {
  BurstMode,
  DesktopMacOutlined,
  FeaturedVideo,
  Help,
  Movie,
  Pageview,
  People,
  Receipt,
  Terminal,
  BarChart,
  OpenInNew,
} from "@mui/icons-material";
import {
  List,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEventsPreselected } from "src/api/useEventsPreselected";
import { useReportScratchEvents } from "src/api/useReportScratchEvents";
import { accountsManagementRoute } from "src/pages/AccountsManagement/AccountsManagement.route";
import { dateTimeSearchInitialRoute } from "src/pages/DateTimeSearchInitial/DateTimeSearchInitial.route";
import { devPlaygroundRoute } from "src/pages/DevPlayground/DevPlayground.route";
import { featureGateDemoRoute } from "src/pages/FeatureGateDemo/FeatureGateDemo.route";
import { mediaCenterRoute } from "src/pages/MediaCenter/MediaCenter.route";
import { powerSearchRoute } from "src/pages/PowerSearch/PowerSearch.route";
import { reportsRoute } from "src/pages/Reports/Reports.route";
import { ReportsPageTabMode } from "src/pages/Reports/Reports.const";
import { analyticsLiteRoute } from "src/pages/ReportAnalysisLite/AnalyticsLite.route";
import { isDebugEnv } from "src/utils/isDebugEnv";
import { snapshotsRoute } from "src/pages/Snapshots/Snapshots.route";
import { userManagementRoute } from "src/pages/UserManagement/UserManagement.route";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import {
  useCurrentUserHighestRole,
  USER_ROLE,
} from "src/utils/useUserRoleList";
import { ReportsNavItemPopperContent } from "../ReportsNavItemPopperContent/ReportsNavItemPopperContent";
import { ReportsIcon } from "../icons/ReportsIcon";
import { DateTimeSearch } from "../icons/DateTimeSearch";
import { DrawerNavMenuItem } from "./DrawerNavMenuItem";
import { DrawerNavMenuItemBadge } from "./DrawerNavMenuItemBadge";
import { appErrorPageRoute } from "src/pages/AppErrorPage/AppErrorPage.route";

export const DrawerNavMenu = (props: Omit<StackProps, "children">) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const showDevMenuItems = isDebugEnv();

  const userHighestRole = useCurrentUserHighestRole();
  const isAccountManagementEnabled =
    !isMobile &&
    (userHighestRole === USER_ROLE.Admin ||
      userHighestRole === USER_ROLE.AccountManager);
  const isUserManagementEnabled =
    !isMobile &&
    (userHighestRole === USER_ROLE.Admin ||
      userHighestRole === USER_ROLE.AccountManager ||
      userHighestRole === USER_ROLE.UserManager);

  const {
    highlightedEventsIds,
    tooltipIsOpen: preselectedEventsTooltip,
    hideTooltip: hidePreselectedEventsTooltip,
    allAddedEventsLenght,
  } = useEventsPreselected();
  const reportScratchEvents = useReportScratchEvents({
    request: {
      query: {
        from: 0,
        size: 1,
      },
    },
  });

  const reportsPath = reportsRoute.makeUrl(undefined, {
    pageTab: !!reportScratchEvents.data?.total
      ? ReportsPageTabMode.events
      : ReportsPageTabMode.reports,
  });

  return (
    <Stack
      id="drawer-container"
      justifyContent="space-between"
      flexGrow={1}
      width="100%"
      {...props}
    >
      <List disablePadding>
        <DrawerNavMenuItem
          path={watchListRoute.path}
          label="Watchlist"
          tooltip="Watchlist"
          icon={<DesktopMacOutlined />}
        />
        <DrawerNavMenuItem
          path={powerSearchRoute.path}
          label="Power Search"
          tooltip="Power Search"
          icon={<Pageview />}
        />
        <DrawerNavMenuItemBadge
          path={reportsPath}
          label="Reports"
          popperProps={{
            isOpen: preselectedEventsTooltip,
            hide: hidePreselectedEventsTooltip,
            content: <ReportsNavItemPopperContent />,
          }}
          tooltip={
            !preselectedEventsTooltip
              ? "Reports with events to report or send"
              : undefined
          }
          icon={<ReportsIcon />}
          badgeProps={{
            type:
              !!reportScratchEvents.data?.total &&
              !reportScratchEvents.isError &&
              !highlightedEventsIds.length &&
              !allAddedEventsLenght
                ? "dot"
                : "content",
            badgeContent: highlightedEventsIds.length + allAddedEventsLenght,
          }}
        />
        <DrawerNavMenuItem
          path={dateTimeSearchInitialRoute.path}
          label="Date/Time Search"
          tooltip="Search events at specific date or time"
          icon={<DateTimeSearch />}
        />
        <DrawerNavMenuItem
          path={mediaCenterRoute.path}
          label="Media Center"
          tooltip="Media Center with saved video clips for later"
          icon={<Movie />}
        />
        <DrawerNavMenuItem
          path={snapshotsRoute.path}
          label="Snapshot"
          tooltip="Snapshot of live broadcast television and radio"
          icon={<BurstMode />}
        />
        <DrawerNavMenuItem
          path={analyticsLiteRoute.path}
          label="Data Analysis"
          tooltip="Watchlist Data Analysis"
          icon={<BarChart />}
        />
        {isAccountManagementEnabled && (
          <DrawerNavMenuItem
            path={accountsManagementRoute.path}
            label="Accounts"
            tooltip="All customer accounts with billing information"
            icon={<Receipt />}
          />
        )}
        {isUserManagementEnabled && (
          <DrawerNavMenuItem
            path={userManagementRoute.path}
            label="User Management"
            tooltip="All users inside system from all accounts"
            icon={<People />}
          />
        )}
      </List>
      <List disablePadding>
        <DrawerNavMenuItem
          path="https://insight.tveyes.com/help/"
          icon={<Help />}
          label="Help & Support"
          tooltip="Help & Support"
          secondaryIcon={
            <OpenInNew
              sx={{
                width: 20,
                height: 20,
                marginRight: isMobile ? "50px" : 0,
                color: "text.disabled",
              }}
            />
          }
        />
        {showDevMenuItems && (
          <DrawerNavMenuItem
            path={featureGateDemoRoute.path}
            label="Feature Gate"
            tooltip="A route protected by feature gate"
            icon={<FeaturedVideo />}
          />
        )}
        {showDevMenuItems && (
          <DrawerNavMenuItem
            path={appErrorPageRoute.path}
            label="Error Boundary"
            tooltip="A route that has fatal error"
            icon={<BrokenImageIcon />}
          />
        )}
        {showDevMenuItems && (
          <DrawerNavMenuItem
            path={devPlaygroundRoute.path}
            label="Dev's Playground"
            tooltip="Craft artifacts, make potions, cast spells or otherwise do your magic here"
            icon={<Terminal />}
          />
        )}
      </List>
    </Stack>
  );
};
