import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type HubSpotFFTAssignContactsRouteQueryParams = {
  company_id: string;
  campaign_id: string;
  owner_id: string;
};

export const hubSpotFFTAssignContactsRoute = new AppRouteLazyBuilder<
  void,
  HubSpotFFTAssignContactsRouteQueryParams
>({
  path: "/hub-spot/fft/assign-contacts",
  lazy: async () => {
    const { HubSpotFFTAssignContactsPage } = await import(
      "./HubSpotFFTAssignContacts.page"
    );
    return { Component: HubSpotFFTAssignContactsPage };
  },
});
