import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { ACTIVITY_LOG_ROLES } from "src/utils/useUserRoleList";

type UserManagementActivityLogsParams = {
  userId: string;
};

export const userManagementActivityLogsRoute =
  new AppRouteLazyBuilder<UserManagementActivityLogsParams>({
    path: "/user-management/logs/:userId",
    lazy: async () => {
      const { UserManagementActivityLogPage } = await import(
        "./UserManagementActivityLogs.page"
      );
      return {
        Component: function () {
          return (
            <PermissionGuard
              component={UserManagementActivityLogPage}
              allowedRoles={ACTIVITY_LOG_ROLES}
              disableMobile
              redirectTo="/"
              // silent
            />
          );
        },
      };
    },
  });
