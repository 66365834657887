import { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useSessionContext } from "src/api/useSessionContext";
import { ConsentManagementResponse } from "src/api/useConsentManagement";
import { useConsentManagementUpdate } from "src/api/useConsentManagementUpdate";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";
import { useIsMobile } from "src/utils/useIsMobile";
import { DialogBase } from "../DialogBase/DialogBase";

type CustomizeConsentPreferencesDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  closeOnLeftSide?: boolean;
};

const defaultState = {
  permitNecessary: true,
  permitFunctional: true,
  permitAnalytics: true,
};

export const CustomizeConsentPreferencesDialog = ({
  onClose,
  open,
  closeOnLeftSide,
  ...props
}: CustomizeConsentPreferencesDialogProps) => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();

  const { userId, userConsent } = useSessionContext();

  const [selectedValues, setSelectedValues] =
    useState<ConsentManagementResponse>(defaultState);

  useEffect(() => {
    if (!userConsent) return;
    setSelectedValues(userConsent);
  }, [userConsent]);

  const updateConsentPreferences = useConsentManagementUpdate({
    options: {
      onSettled() {
        onClose && onClose();
      },
    },
  });

  const saveOptions = () => {
    updateConsentPreferences.mutate({
      path: {
        id: userId,
        permitNecessary:
          selectedValues.permitNecessary ?? defaultState.permitNecessary,
        permitFunctional:
          selectedValues.permitFunctional ?? defaultState.permitFunctional,
        permitAnalytics:
          selectedValues.permitAnalytics ?? defaultState.permitAnalytics,
      },
    });
  };

  const acceptDefault = () => {
    updateConsentPreferences.mutate({
      path: {
        id: userId,
        permitNecessary: defaultState.permitNecessary,
        permitFunctional: defaultState.permitFunctional,
        permitAnalytics: defaultState.permitAnalytics,
      },
    });
  };

  if (isMobile) {
    return (
      <DialogBase
        fullWidth
        closeOnLeftSide
        mobileFullHeight
        open={open}
        onClose={onClose}
        title="Customize Consent Preferences"
        {...props}
        children={
          <Stack
            m="auto"
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
            mt={2}
          >
            <Typography variant="body1" mb={2}>
              We use cookies to improve your browsing experience, deliver
              personalized ads and content, and analyze our traffic. By clicking
              “Accept Default”, you agree to our use of cookies.
            </Typography>
            <FormControlLabel
              checked={selectedValues?.permitNecessary}
              control={<Switch size="small" />}
              label={
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography variant="subtitle1">Necessary</Typography>
                  <Typography variant="caption" color="success.main">
                    Always Active
                  </Typography>
                </Stack>
              }
              sx={{ ml: 0, whiteSpace: "nowrap" }}
            />
            <Typography variant="body1" mt={1} mb={2} ml={0}>
              Necessary cookies enable core features like secure login (Auth0),
              Authentication, and Load Balancer Cookies. They don’t store
              personal data.
            </Typography>

            <FormControlLabel
              checked={selectedValues?.permitFunctional}
              onChange={() =>
                setSelectedValues((pV) => ({
                  ...pV,
                  permitFunctional: !pV?.permitFunctional,
                }))
              }
              control={<Switch size="small" />}
              label={<Typography variant="subtitle1">Functional</Typography>}
              sx={{ ml: 0, whiteSpace: "nowrap" }}
            />
            <Typography variant="body1" mt={1} mb={2} ml={0}>
              Functional cookies help the Insight application perform key
              duties, such as sharing and support. For example: Intercom,
              Hubspot.
            </Typography>

            <FormControlLabel
              checked={selectedValues?.permitAnalytics}
              onChange={() =>
                setSelectedValues((pV) => ({
                  ...pV,
                  permitAnalytics: !pV?.permitAnalytics,
                }))
              }
              control={<Switch size="small" />}
              label={<Typography variant="subtitle1">Analytics</Typography>}
              sx={{ ml: 0, whiteSpace: "nowrap" }}
            />
            <Typography variant="body1" mt={1} mb={2} ml={0}>
              Analytical cookies, using tools like Hotjar and Google Analytics,
              track visitor activity, bounce rates, and traffic sources to
              enhance your experience.
            </Typography>
          </Stack>
        }
        footer={
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
            width="100%"
          >
            <Button
              sx={{ color: palette.text.primary, width: "100%" }}
              onClick={saveOptions}
              variant="outlined"
            >
              Save My Options
            </Button>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={acceptDefault}
            >
              Accept Default
            </Button>
          </Stack>
        }
      />
    );
  }

  return (
    <DialogBase
      fullWidth
      open={open}
      onClose={onClose}
      title="Customize Consent Preferences"
      {...props}
      children={
        <Stack
          m="auto"
          flexGrow={1}
          flexDirection="column"
          justifyContent="center"
          mt={2}
        >
          <Typography variant="body1" mb={2}>
            We use cookies to improve your browsing experience, deliver
            personalized ads and content, and analyze our traffic. By clicking
            “Accept Default”, you agree to our use of cookies.
          </Typography>
          <FormControlLabel
            checked={selectedValues?.permitNecessary}
            control={<Switch size="small" />}
            label={
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Typography variant="subtitle1">Necessary</Typography>
                <Typography variant="caption" color="success.main">
                  Always Active
                </Typography>
              </Stack>
            }
            sx={{ ml: 0, whiteSpace: "nowrap", gap: 1 }}
          />
          <Typography variant="body1" mb={2} ml={6}>
            Necessary cookies enable core features like secure login (Auth0),
            Authentication, and Load Balancer Cookies. They don’t store personal
            data.
          </Typography>

          <FormControlLabel
            checked={selectedValues?.permitFunctional}
            onChange={() =>
              setSelectedValues((pV) => ({
                ...pV,
                permitFunctional: !pV?.permitFunctional,
              }))
            }
            control={<Switch size="small" />}
            label={<Typography variant="subtitle1">Functional</Typography>}
            sx={{ ml: 0, whiteSpace: "nowrap", gap: 1 }}
          />
          <Typography variant="body1" mb={2} ml={6}>
            Functional cookies help the Insight application perform key duties,
            such as sharing and support. For example: Intercom, Hubspot.
          </Typography>

          <FormControlLabel
            checked={selectedValues?.permitAnalytics}
            onChange={() =>
              setSelectedValues((pV) => ({
                ...pV,
                permitAnalytics: !pV?.permitAnalytics,
              }))
            }
            control={<Switch size="small" />}
            label={<Typography variant="subtitle1">Analytics</Typography>}
            sx={{ ml: 0, whiteSpace: "nowrap", gap: 1 }}
          />
          <Typography variant="body1" mb={2} ml={6}>
            Analytical cookies, using tools like Hotjar and Google Analytics,
            track visitor activity, bounce rates, and traffic sources to enhance
            your experience.
          </Typography>
        </Stack>
      }
      footer={
        <Stack flexDirection="row" justifyContent="flex-end" gap={2}>
          <Button color="error" onClick={onClose}>
            Close
          </Button>
          <Button
            sx={{ color: palette.text.primary }}
            onClick={saveOptions}
            variant="outlined"
          >
            Save My Options
          </Button>
          <Button variant="contained" onClick={acceptDefault}>
            Accept Default
          </Button>
        </Stack>
      }
    />
  );
};
