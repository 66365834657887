import { ReactNode } from "react";
import { Stack, StackProps } from "@mui/material";
import { useIsFullScreen } from "src/utils/useIsFullScreen";
import { StackScroll } from "./StackScroll/StackScroll";

export type PageLayoutDesktopProps = {
  // header at the top
  header?: ReactNode;
  // content that can be scrolled or limited to viewport
  content?: ReactNode;
  // footer at the top
  footer?: ReactNode;
  /** control if page layout scrolls it content */
  overflow?: "hidden" | "scroll";
  // onScroll event of the scroll container
  onScroll?: StackProps["onScroll"];
  // change default dimensions for layout
  maxWidthNormal?: number;
  maxWidthFullscreen?: number;
};

export function PageLayoutDesktop({
  header,
  content,
  footer,
  overflow = "scroll",
  maxWidthNormal = 1152,
  maxWidthFullscreen = 1528,
  onScroll,
}: PageLayoutDesktopProps) {
  const isFullScreen = useIsFullScreen();
  const maxContentWidth = isFullScreen ? maxWidthFullscreen : maxWidthNormal;

  return (
    <Stack
      id="page-layout-desktop"
      pl={2}
      pr={0.5}
      pt={5}
      flex={1}
      overflow="hidden"
    >
      {header && (
        <Stack
          pr={1.5}
          mx="auto"
          width="100%"
          maxWidth={maxContentWidth + 16}
          children={header}
        />
      )}
      <StackScroll
        id="page-layout-desktop-scroll"
        mb={2}
        pr={0.5}
        height="100%"
        onScroll={onScroll}
        overflow={overflow}
        children={
          <Stack
            id="page-layout-desktop-content"
            mx="auto"
            width="100%"
            flex={1}
            maxWidth={maxContentWidth}
            children={content}
            overflow={
              overflow === "scroll"
                ? // when page layout scrolls - show entre content
                  "visible"
                : // when page layout does not scroll - limit content to page
                  "hidden"
            }
          />
        }
      />
      {footer && (
        <Stack
          pr={1.5}
          mx="auto"
          width="100%"
          maxWidth={maxContentWidth + 16}
          children={footer}
        />
      )}
    </Stack>
  );
}
